export const LANDING = '/';
export const INDEX = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';
export const EULA ='/';
export const REGCONFIRM = '/regconf';
export const BOOKMARKED = '/bookmarked';
export const RESULTS = '/filterResults';
export const TERMS = '/terms';
export const CONTACT = '/contact';
export const UPDATE_EMAIL = '/changeEmail';
export const UPDATE_PASSWORD = '/changePassword';
export const MANUALS = '/manuals';