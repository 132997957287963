import React from 'react';

import { withFirebase } from '../Firebase';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/pro-light-svg-icons';

const SignOutButton = ({ firebase }) => (
  <>
        <button
        type="button"
        id="signOutBtn"
        onClick={firebase ? firebase.doSignOut : () => {}}
      ><FontAwesomeIcon className="search" icon={faSignOut} aria-hidden="true" />
        Sign Out
      </button>
  </>
);

export default withFirebase(SignOutButton);
