//import { Link } from "gatsby";
//import PropTypes from "prop-types";
import React from "react";
import footerStyles from '../../styles/footer.module.scss'
//import * as ROUTES from '../../constants/routes';

    var today = new Date();

   const Footer = () => (
        <footer id={footerStyles.colophonPWA} className="footer">
            <div className="wrapper">
                <small className="block-display">©{today.getFullYear()} <a className={footerStyles.footLink} href="https://ceca-acea.org/" target="_Blank" rel="noopener noreferrer">Canadian Elevator Contractors Association</a> <div className={footerStyles.pipe}>|&nbsp;</div>
                <a className={footerStyles.footLink} href="https://info.ceca-mcp.com/terms" target="_blank" rel="noopener noreferrer">Terms &amp; Privacy Policy</a>
                <div className={footerStyles.pipe}>&nbsp;|&nbsp;</div><a className={footerStyles.footLink} target="_blank" rel="noopener noreferrer" href="https://info.ceca-mcp.com/">MCP File Upload</a>
                <span className={footerStyles.phone}>Phone: <a href="tel:14164912414">+1 905-446-0327</a></span>
                </small>
            </div>
        </footer>
    )

export default Footer;
