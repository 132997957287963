const config = {
  apiKey: "AIzaSyCLk4ADlOQSvN0tFr0jpx6eFKuX3yBlekg",
  authDomain: "ceca-40995.firebaseapp.com",
  databaseURL: "https://ceca-40995.firebaseio.com",
  projectId: "ceca-40995",
  storageBucket: "",
  messagingSenderId: "703425303602",

};

class Firebase {

  constructor(app) {
    app.initializeApp(config);

    /* Helper */

    this.serverValue = app.database.ServerValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.database();

    /* Social Sign In Method Provider */

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    this.twitterProvider = new app.auth.TwitterAuthProvider();

    /* Mine */
    this.dbRefFavorite = app.database().ref().child('favorites');
    //this.dbRefFavorite = app.database().ref('users/'+userId).child('favorites')
  }

   sleeper = (ms) =>{ 
    return function(x) {
      return new Promise(resolve => setTimeout(() => resolve(x), ms));
    };
  }
  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () =>
    this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () =>
    this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () =>
    this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.GATSBY_CONFIRMATION_EMAIL_REDIRECT,
    });

    doReAuthenticate = (currentPassword) =>
      this.auth.currentUser.reauthenticateWithCredential(this.emailAuthProvider.credential(this.auth.currentUser.email, currentPassword ));


  doPasswordUpdate = (password, currentPassword) => {
    var user = this.auth.currentUser;
    var cred = this.emailAuthProvider.credential(user.email, currentPassword )
    user.reauthenticateWithCredential(cred).then(function() {
    }).then((_) => this.auth.currentUser.updatePassword(password)).then((_) => {
      window.location.replace(process.env.GATSBY_PASSWORD_CHANGE_REDIRECT);
    }).then((_)=>{
      this.doSignOut();
    })
  }


  /*doFavoriteUpdate = isFavorite =>
    this.auth.currentUser.updateProfile(isFavorite);
  */
  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .once('value')
          .then(snapshot => {
            const dbUser = snapshot.val();

            // check if child favorites exists
            if(! this.db.ref(`users/`+this.auth.currentUser.uid).child('favorites')) {
                this.db.ref(`users/`+this.auth.currentUser.uid).child('favorites');
            }

            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              favorites: authUser.favorites,
              companyName: authUser.companyName,
              companyAddress: authUser.companyAddress,
              companyAddress2: authUser.companyAddress2,
              companyCity: authUser.companyCity,
              companyProvince: authUser.companyProvince,
              companyPostal: authUser.companyPostal,
              name: authUser.username,
              phone: authUser.phone,
              ...dbUser,
            };
            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** User API ***

  user = uid => this.db.ref(`users/${uid}`);

  users = () => this.db.ref('users');

  // *** Message API ***

  message = uid => this.db.ref(`messages/${uid}`);

  messages = () => this.db.ref('messages');

  doFavoriteUpdate(isFavorite, manual, title, slug, uri, language, type, manufacturer, fileURL){
      if(isFavorite === true) {


                this.db.ref(`users/`+this.auth.currentUser.uid).child('favorites').child(manual).update({
                    slug : slug,
                    title : title,
                    uri : uri,
                    language : language,
                    type : type,
                    manufacturer : manufacturer,
                    fileURL : fileURL

                });

         //alert('REFRESH!');

        } else {

            this.db.ref(`users/`+this.auth.currentUser.uid).child('favorites').child(manual).update({
                slug : null,
                title : null,
                uri : null,
                language : null,
                type : null,
                manufacturer : null,
                fileURL : null
            });
        }

    }

    doUpdateCompany(newCompanyName){
      this.db.ref(`users/`+this.auth.currentUser.uid).update({
        companyName: newCompanyName
      });
    }
    doUpdateCompanyAddress(newCompanyAddress){
      this.db.ref(`users/`+this.auth.currentUser.uid).update({
        companyAddress: newCompanyAddress
      });
    }

    doUpdateCompanyAddress2(newCompanyAddress2){
      this.db.ref(`users/`+this.auth.currentUser.uid).update({
        companyAddress2: newCompanyAddress2
      });
    }
    doUpdateCompanyCity(newCompanyCity){
      this.db.ref(`users/`+this.auth.currentUser.uid).update({
        companyCity: newCompanyCity
      });
    }
    doUpdateCompanyProvince(newCompanyProvince){
      this.db.ref(`users/`+this.auth.currentUser.uid).update({
        companyProvince: newCompanyProvince
      });
    }
    doUpdateCompanyPostal(newCompanyPostal){
      this.db.ref(`users/`+this.auth.currentUser.uid).update({
        companyPostal: newCompanyPostal
      });
    }
    doUpdateName(newName){
      this.db.ref(`users/`+this.auth.currentUser.uid).update({
        username: newName
      });
    }
    doUpdatePhone(newPhone){
      this.db.ref(`users/`+this.auth.currentUser.uid).update({
        phone: newPhone
      });
    }

     doUpdateEmail(newEmail, currentPassword){


          this.auth.currentUser.updateEmail(newEmail)
          .then(this.sleeper(800))      
          .then((_) => {
            this.auth.currentUser.sendEmailVerification({
              url: process.env.GATSBY_CONFIRMATION_EMAIL_REDIRECT,
            });

         }).then(this.sleeper(1500)) 
          .then((_) =>{
         this.db.ref(`users/`+this.auth.currentUser.uid).update({
            email: newEmail
          })
      })
        .then((_)=>{ 
          window.location.replace(process.env.GATSBY_EMAIL_CHANGE_REDIRECT);
          
        })
        .then((_) =>{
        this.doSignOut();
        })

    }

}

let firebase;

function getFirebase(app, auth, database) {
  if (!firebase) {
    firebase = new Firebase(app, auth, database);
  }

  return firebase;
}

export default getFirebase;
